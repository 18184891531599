<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Tạo mới</h6>
        </template>
        <b-row>
          <b-col sm="6">
            <b-form-group label="Mô tả (*)">
              <b-form-input
                  v-model="input.title"
                  placeholder="Mô tả"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group label="Loại (*)">
              <b-form-select
                  v-model="input.type"
                  :options="options.type"
                  placeholder="Loại"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6">
            <b-form-group label="Danh sách đối tượng áp dụng (*)">
              <multiselect
                  v-model="input.list"
                  :options="options.list_id"
                  :multiple="true"
                  label="name"
                  track-by="id"
                  value="id"
                  @change="listProduct"
              ></multiselect>
            </b-form-group>
          </b-col>
          <b-col sm="6" v-if="input.type === 'PRODUCT'">
            <b-form-group label="Danh sách sản phẩm áp dụng (*)">
              <multiselect
                  v-model="input.product_id"
                  :options="options.product_id"
                  :multiple="true"
                  label="name"
                  track-by="id"
                  value="id"
              ></multiselect>
            </b-form-group>
          </b-col>
        </b-row>

<!--        <b-row>-->
<!--          <b-col sm="6">-->
<!--            <b-form-group label="Tên class thực hiện (*)">-->
<!--              <b-form-select-->
<!--                  v-model="input.class_name"-->
<!--                  :options="options.class_name"-->
<!--                  placeholder="Nhập tên classname chứa action"-->
<!--              ></b-form-select>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
<!--        </b-row>-->

        <b-row>
            <b-col sm="6">
                <b-form-group label="Từ ngày(*):">
                    <date-picker
                        v-model="input.time_set"
                        type="datetime"
                        placeholder="Từ ngày"
                        :input-attr="{ required: 'true', valid_at: '' }"
                        format="YYYY-MM-DD HH:mm:ss"
                        value-type="format"
                        class="w-100"
                    ></date-picker>
                </b-form-group>
            </b-col>
            <b-col sm="6">
                <b-form-group label="Đến ngày(*):">
                    <date-picker
                        v-model="input.time_off"
                        type="datetime"
                        placeholder="Đến ngày"
                        :input-attr="{ required: 'true', valid_at: '' }"
                        format="YYYY-MM-DD HH:mm:ss"
                        value-type="format"
                        class="w-100"
                    ></date-picker>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
                id="input-dk-2"
                label="Hoạt động"
                label-for="ms-desc"
            >

              <div class="form-inline">
                <b-form-radio :value="1" v-model="input.params.active">Bật</b-form-radio>
                <b-form-radio :value="0" style="margin-left: 30px" v-model="input.params.active">Tắt</b-form-radio>
                <b-form-radio :value="2" style="margin:0 30px" v-model="input.params.active">Bảo trì</b-form-radio>

                  <b-form-input
                      v-if="input.type === 'PRODUCT'"
                      v-model="input.params.tag_text"
                      placeholder="Text tag"
                  ></b-form-input>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col class="text-center">
            <b-button class="mr-2" variant="outline-primary" @click="update()"
            >Lưu
            </b-button>
            <b-button class="mr-2" variant="outline-danger" @click="cancel()"
            >Hủy
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from "vue-multiselect";

Vue.component("multiselect", Multiselect);
const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  components: { DatePicker },
  data() {
    return {
      isFirstLoad: true,
      id: this.$route.query.id ?? null,
      input: {
        class_name: "App\\Schedule\\Service\\ServiceOnOffSchedule",
        title: null,
        type: "SERVICE",
        time_set: null,
        time_off: null,
        params: {
          active: 1,
            tag_text:''
        },
        status: 0,
        list: [],
        product_id: [],
      },
      options: {
          type: [
            { value: "SERVICE", text: "Dịch vụ" },
            { value: "PRODUCT", text: "Sản phẩm" },
          ],
          list_id: [],
          product_id: [],

        class_name: [
          { text: 'ON/OFF Service', value: "App\\Schedule\\Service\\ServiceOnOffSchedule" },
          { text: 'ON/OFF Sản phẩm', value: "App\\Schedule\\Service\\ProductOnOffSchedule" },
        ]
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Config hẹn giờ", route: "schedule" },
    ]);

    this.getServiceAndItem();
  },
  methods: {
    async getServiceAndItem() {
      this.getItem(); // Gọi hàm getItem sau khi service đã được tải xong
    },
    cancel() {
      return this.$router.push({ name: "schedule" });
    },

    validateStoreForm() {
      if (this.input.title === "" || this.input.title === null) {
        this.notifyAlert("warn", "Mô tả không được để trống");
        return false;
      }
      if (this.input.type === "" || this.input.type === null) {
        this.notifyAlert("warn", "Loại không được để trống");
        return false;
      }

      if (this.input.list === [] || this.input.list === null || this.input.list.length === 0) {
        this.notifyAlert("warn", "Đối tượng áp dụng không được để trống");
        return false;
      }

      if (this.input.class_name === "" || this.input.class_name === null) {
        this.notifyAlert("warn", "Tên class không được để trống");
        return false;
      }

      if (this.input.time_set === "" || this.input.time_set === null) {
        this.notifyAlert("warn", "Thời gian áp dụng không được để trống");
        return false;
      }

      if (this.input.type === "PRODUCT" && this.input.product_id.length <= 0) {
        this.notifyAlert("warn", "Sản phẩm không được để trống");
        return false;
      }

      return true;
    },

    update() {
      if (!this.validateStoreForm()) {
        return;
      }
      let inputParam = this.input;
      let productsId = inputParam.product_id;
      inputParam.list_id = [];

      this.input.list.forEach( item => {
        inputParam.list_id.push(item.id)
      })

      delete(inputParam.list)
      // inputParam.list_id = JSON.stringify(inputParam.list_id)
      // inputParam.params = JSON.stringify(inputParam.params)

      inputParam.product_id = [];
      productsId.forEach( item => {
        inputParam.product_id.push(item.id)
      })

      inputParam.list_id = JSON.stringify(inputParam.list_id)
      inputParam.product_id = JSON.stringify(inputParam.product_id)
      inputParam.params = JSON.stringify(inputParam.params)

      this.$bus.$emit("show-loading", true);
      CmsRepository.updateSchedule(this.id, inputParam)
        .then((response) => {
          if (response) {
            this.notifyAlert("success", response.data.message);
            this.$bus.$emit("show-loading", false);
            return this.$router.push({ name: "schedule" });
          } else {
            this.notifyAlert("warn", response.data.message);
            this.$bus.$emit("show-loading", false);
            return false;
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },

    async listService() {
      this.$bus.$emit("show-loading", true);
      let params = {
        type: "SERVICE"
      };
      const response = await CmsRepository.listServiceFee(params);
      this.options.list_id = response?.data?.data;
      this.$bus.$emit("show-loading", false);
      return true;
    },

    async listProduct() {
      this.$bus.$emit("show-loading", true);
      let services = this.convertProduct(this.input.list);
      let params = {
        service_id: services
      };
      const response = await CmsRepository.listAllProducts(params);
      // this.options.product_id = response?.data?.data;

      let products = response?.data?.data;
      let result = [];
      products.forEach((product) => {
        product.name = typeof product.service !== "undefined" ? product.name + ` - (${product.service.code})` : product.name;
        result.push(product);
      })

      this.options.product_id = result;

      this.$bus.$emit("show-loading", false);
      return true;
    },
    checkProductByService() {
      let services = this.input.list;
      let servicesId = [];
      services.forEach( item => {
        servicesId.push(item.id)
      })
      let products = this.input.product_id;

      if (products.length <= 0) {
        return true;
      }

      let newProducts = [];
      products.forEach((product) => {
        if (servicesId.includes(product.service_id)) {
          newProducts.push(product);
        }
      });
      this.input.product_id = newProducts;
    },
    getItem() {
      this.items = [];
      this.$bus.$emit("show-loading", true);
      CmsRepository.detailSchedule(this.id)
        .then(async (response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          let result = response.data.data;
          this.input.class_name = result.class_name;
          this.input.time_set = result.time_set;
          this.input.time_off = result.time_off;
          this.input.type = result.type;
          this.input.params = JSON.parse(result.params);
          this.input.title = result.title;

          let products = [];
          let services = [];
          await this.listService(); // Chờ đợi cho service được tải xong
          if (result.type === "PRODUCT") {
            let data = JSON.parse(result.list_id);
            services = data.services;
            products = data.products;

            this.input.list = [];
            this.options.list_id.forEach( item => {

              if (services.includes(String(item.id))) {
                this.input.list.push(item);
              }
            });

            await this.listProduct();

            this.input.product_id = [];
            this.options.product_id.forEach( item => {
              if (products.includes(String(item.id))) {
                this.input.product_id.push(item)
              }
            });
          } else {
            services = JSON.parse(result.list_id);
            this.input.list = [];
            this.options.list_id.forEach( item => {
              if (services.includes(item.id)) {
                this.input.list.push(item);
              }
            });
          }

          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },

    convertProduct(services) {
      let result = [];
      services.forEach(function(item) {
        result.push(item.id);
      })

      return result;
    }
  },

  created() {

  },
  watch: {
    "input.list": function(newServiceId) {
      if (this.isFirstLoad) {
        this.isFirstLoad = false;
        return;
      }

      if (this.input.type === "PRODUCT") {
        if (newServiceId.length > 0) {
          this.listProduct();
          this.checkProductByService();
          return;
        }

        this.input.product_id = [];
        this.options.product_id = [];
      }
    },
    "input.type": function(newType) {
      if (newType === "PRODUCT") {
        this.input.class_name = "App\\Schedule\\Service\\ProductOnOffSchedule";
      } else {
        this.input.class_name = "App\\Schedule\\Service\\ServiceOnOffSchedule";
        this.input.product_id = [];
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.mx-datepicker {
  width: 50% !important;
}
.number-mini {
  font-size: 10px;
}
</style>
